import './App.css';
import {CssBaseline} from "@mui/material";
import React from "react";
import {Default} from "./layouts/Default";
import {Route, Routes} from "react-router-dom";
import {Event} from "./pages/Event";

function App() {
    return (
        <div>
            <CssBaseline/>
            <Routes>
                <Route path={'/'} element={<div></div>}/>
                <Route path={'/:id'} element={<Event/>}/>
            </Routes>
            <Default>

            </Default>
        </div>
    );
}

export default App;
